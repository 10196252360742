import document from 'document';
import { helpers } from 'App';

/**
 * Parse a given URL and return an object of its parts
 *
 * @param  {String}   url The URL to parse
 * @return {Location}     An object of type Location
 */
function parseUrl(url) {
  const a = document.createElement('a');
  a.href = url;

  return {
    set href(value) {
      a.href = value;
    },
    get href() {
      return a.href;
    },
    set protocol(value) {
      a.protocol = value;
    },
    get protocol() {
      return a.protocol;
    },
    set host(value) {
      a.host = value;
    },
    get host() {
      return a.host;
    },
    set hostname(value) {
      a.hostname = value;
    },
    get hostname() {
      return a.hostname;
    },
    set port(value) {
      a.port = value;
    },
    get port() {
      return a.port;
    },
    set pathname(value) {
      a.pathname = value;
    },
    get pathname() {
      return a.pathname;
    },
    set search(value) {
      a.search = value;
    },
    get search() {
      return a.search;
    },
    set hash(value) {
      a.hash = value;
    },
    get hash() {
      return a.hash;
    },
    set username(value) {
      a.username = value;
    },
    get username() {
      return a.username;
    },
    set password(value) {
      a.password = value;
    },
    get password() {
      return a.password;
    },
    set origin(value) {
      a.origin = value;
    },
    get origin() {
      return a.origin;
    },
  };
}

// Export to helpers
helpers.parseUrl = parseUrl;
